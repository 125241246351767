import { request } from '../../../api/axios'

const state = {
  pageIndex: 0,
  pageRows: 200,
  totalCount: 0,
  deviceList: []
}

const getters = {
  totalCount (state) {
    return state.totalCount
  },
  pageIndex (state) {
    return state.pageIndex
  },
  pageRows (state) {
    return state.pageRows
  },
  getAdminDeviceList (state) {
    return state.deviceList
  },
  getDeviceDataById: (state) => (id) => {
    return state.deviceList.find(item => item.deviceId === id)
  }
}

const mutations = {
  setPageIndex (state, index) {
    state.pageIndex = index
  },
  setAdminDeviceList (state, data) {
    state.deviceList.length = 0
    state.totalCount = data.total
    data.rows.forEach(element => {
      state.deviceList.push(element)
    })
  }
}

const actions = {
  async getAdminDeviceList ({ state, rootState }, data = {}) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows
    }
    params = { ...params, ...data }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getDeviceOptionListByKeyword ({ rootState }, searchKeyword) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 20,
      keyword: searchKeyword
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getDeviceBySerialNumber ({ rootState }, serialNumber) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 1,
      deviceSerialNumber: serialNumber
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getDevicesContentByArraySerialNumber ({ rootState }, arrSerialNumber) {
    const params = {
      pageIndex: 0,
      pageRows: 1000,
      userToken: rootState.userToken,
      deviceSerialNumbers: arrSerialNumber
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getDeviceByFleetId ({ rootState }, fleetId) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 100,
      fleetId
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getAdminSearchDeviceByNumber ({ state, rootState }, searchNumber) {
    const params = {
      userToken: rootState.userToken,
      pageIndex: state.pageIndex,
      pageRows: state.pageRows,
      keyword: searchNumber
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async updateAdminDeviceData ({ rootState }, updateData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...updateData }
    console.log(JSON.stringify(params))
    return request('/api/editDevice', JSON.stringify(params))
  },
  async createDeviceData ({ rootState }, addData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...addData }
    return request('/api/addDevices', JSON.stringify(params))
  },
  async allocationDevice ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('/api/allocationDevice', JSON.stringify(params))
  },
  async getExpiredServiceDevices ({ rootState }, data) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...data }
    return request('api/getExpireServiceDevices', JSON.stringify(params))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
