<template>
  <transition name="slide">
    <div class="leftMenu" v-show="showLeftSide">
      <div class="row bg-dark border p-1 topTitle">
        <label class="col text-white">{{ t('LEFT_LIST.TITLE') }}</label>
      </div>
      <div class="row bg-info border searchBar">
        <label class="d-flex text-white">排序方式：</label>
        <div class="mx-2">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" v-model="sortType" v-bind:value="'serialNumber'" @change="onSortTypeChanged" :disabled="disableSort" checked>
            <label class="form-check-label" for="inlineRadio1">設備號碼</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" v-model="sortType" v-bind:value="'carName'" @change="onSortTypeChanged" :disabled="disableSort">
            <label class="form-check-label" for="inlineRadio2">車輛名稱</label>
          </div>
        </div>
      </div>
      <div class="row carList border">
        <tree-view
          :checked="fleetData.isChecked ?? false"
          :data="fleetData"
          :allExpend="true"
          @selectCallback="onSelectItemCallback"
          @rightClickCallback="onRightClickCallback"/>
      </div>
    </div>
  </transition>
  <right-click-fleet-menu ref="RightClickFleetMenuList" :posX="menuLeft" :posY="menuTop" @rightMenuCallback="onFleetMenuCallback"/>
  <right-click-device-menu ref="RightClickDeviceMenuList" :posX="menuLeft" :posY="menuTop" @rightMenuCallback="onDeviceMenuCallback"/>
  <right-click-main-menu ref="RightClickMainMenuList" :posX="menuLeft" :posY="menuTop" @rightMenuCallback="onMainMenuCallback"/>
  <modal-sub-fleet ref="ModalSubFleetDialog" @modalCallback="onModalSubFleetCallback"/>
  <modal-client-device-view ref="ModalClientDeviceViewDialog" @updateCallback="onModalDeviceCallback"/>
  <modal-client-electoric-fence ref="ModalClientElectoricFenceDialog" @modalCallback="onModalFenceCallback"/>
  <modal-client-marker-point ref="ModalClientMarkerPointDialog" @modalCallback="onMarkerPointCallback"/>
  <modal-show-message ref="ModalShowMessageDialog" @modalCallback="onShowReciverCallback"/>
  <modal-error-msg ref="ModalErrorDialg"/>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { computed, inject, onMounted, onUnmounted, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useCookies } from 'vue3-cookies'
import TreeView from '../Common/TreeView.vue'
import ModalErrorMsg from '../Modal/ModalErrorMsg.vue'
import RightClickFleetMenu from './RightClickFleetMenu.vue'
import RightClickDeviceMenu from './RightClickDeviceMenu.vue'
import RightClickMainMenu from './RightClickMainMenu.vue'
import ModalSubFleet from '../Modal/ModalSubFleet.vue'
import { useRouter } from 'vue-router'
import ModalClientDeviceView from '../Modal/client/ModalClientDeviceView.vue'
import ModalClientElectoricFence from '../Modal/client/ModalClientElectoricFence.vue'
import ModalClientMarkerPoint from '../Modal/client/ModalClientMarkerPoint.vue'
import ModalShowMessage from '../Modal/client/ModalShowMessage.vue'
export default {
  props: ['showLeft'],
  setup (props) {
    const { t } = useI18n()
    const { cookies } = useCookies()
    const store = useStore()
    const router = useRouter()
    const tools = inject('tools')
    const configData = inject('configData')
    const defaultData = inject('defaultData')

    const showLeftSide = computed(() => props.showLeft)
    const RightClickFleetMenuList = ref()
    const RightClickDeviceMenuList = ref()
    const RightClickMainMenuList = ref()
    const ModalSubFleetDialog = ref()
    const ModalClientDeviceViewDialog = ref()
    const ModalClientElectoricFenceDialog = ref()
    const ModalClientMarkerPointDialog = ref()
    const ModalShowMessageDialog = ref()

    const showRightClickMenu = ref(false)
    const menuTop = ref(0)
    const menuLeft = ref(0)

    const sortType = ref('serialNumber')

    const selectData = computed(() => store.getters['clientAccount/selectDeviceList'])
    const fleetListData = computed(() => store.getters['clientAccount/accountFleetListData'])
    const fleetData = computed(() => store.getters['clientAccount/accountDataList'])
    const accountDeviceList = computed(() => store.getters['clientAccount/accountDeviceList'])
    const reflashTime = computed(() => store.getters['setting/getReflashDeviceFreq'])
    const accountFleetList = computed(() => store.getters['clientAccount/accountMenuFleetList'])

    const isShowMessage = ref(false)
    const showIndex = ref(0)
    const announcementList = store.getters.getUserAnnouncementList
    const messageList = store.getters.getUserMessageList

    const disableSort = ref(true)

    const reflashDevice = ref()
    const startReflashData = ref(false)

    function getSettingData () {
      store.dispatch('setting/getAdminSettingData').then((res) => {
        store.commit('setting/setSettingDataList', res.data)
        startReflashDeviceData()
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function startReflashDeviceData () {
      startReflashData.value = true
      reflashDevice.value = setInterval(() => {
        if (startReflashData.value) {
          store.dispatch('clientAccount/reflashDeviceData')
        } else {
          clearInterval(reflashDevice.value)
        }
      }, reflashTime.value * 1000)
    }

    function getListData (isMounted = false) {
      store.commit('clientAccount/clearAllDataList')
      const cookieData = cookies.get(configData.cookieName)
      switch (cookieData.accountType) {
        case 2:
          disableSort.value = false
          getSubFleetData(cookieData)
          break
        case 3:
          disableSort.value = true
          getDeviceData(cookieData, isMounted)
          break
      }
    }

    function getDeviceData (cookie, isMounted) {
      store.dispatch('getClientDeviceData', { token: cookie.token, device: cookie.account }).then((res) => {
        store.commit('clientAccount/addDeviceList', res.data)
        store.commit('clientAccount/setSelectDeviceIndex', 0)
        if (isMounted) {
          fleetData.value.isChecked = true
          onSelectItemCallback(1, fleetData.value)
        }
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function getSubFleetData (cookie) {
      store.dispatch('clientAccount/getSubFleetData', { token: cookie.token, fleetId: cookie.accountId }).then((res) => {
        store.commit('clientAccount/addFleetDataList', res.data)
        getMainFleetDeviceData()
        getFleetDeviceData()
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function getMainFleetDeviceData () {
      store.dispatch('clientAccount/getMainFleetDevice').then((res) => {
        store.commit('clientAccount/addDeviceList', res.data)
      }).catch((err) => {
        console.error(err)
      })
    }

    function getFleetDeviceData () {
      fleetListData.value.forEach(fleetData => {
        store.dispatch('clientAccount/getDeviceByFleetId', fleetData.id).then((res) => {
          store.commit('clientAccount/addDeviceList', res.data)
        }).catch((err) => {
          console.error(err)
        })
      })
    }

    function showReciverMessage () {
      if (isShowMessage.value) {
        showMessageList()
      } else {
        showAnnouncementList()
      }
    }

    function showAnnouncementList () {
      if (announcementList.length > 0 && showIndex.value < announcementList.length) {
        ModalShowMessageDialog.value.showModalWithData(isShowMessage.value, announcementList[showIndex.value])
      } else {
        isShowMessage.value = true
        showIndex.value = 0
        store.commit('clearAnnouncementList')
        showMessageList()
      }
    }

    function showMessageList () {
      if (messageList.length > 0 && showIndex.value < messageList.length) {
        const data = { ...messageList[showIndex.value], ...{ alias: getReciverAlias(messageList[showIndex.value]) } }
        ModalShowMessageDialog.value.showModalWithData(isShowMessage.value, data)
      } else {
        store.commit('clearMessageList')
      }
    }

    function getReciverAlias (messageData) {
      let alias = ''
      switch (messageData.receiverAccountType) {
        case 2:
          alias = accountFleetList.value.find(item => item.id === messageData.receiverAccountId).alias
          break
        case 3:
          // eslint-disable-next-line no-case-declarations
          const deviceData = accountDeviceList.value.find(item => item.deviceId === messageData.receiverAccountId)
          alias = deviceData.carName ?? '' + '(' + deviceData.deviceSerialNumber + ')'
          break
      }
      return alias
    }

    function onShowReciverCallback () {
      showIndex.value += 1
      showReciverMessage()
    }

    function onSelectItemCallback (type, item) {
      switch (type) {
        case 0:
          if (item.data !== undefined) {
            store.commit('clientAccount/removeSelectData', item)
            store.commit('monitor/removeMarkerPointListByDeviceSerialNumber', parseInt(item.data.deviceSerialNumber))
          }
          break
        case 1:
          store.commit('clientAccount/addSelectData', item)
          addMarkerPointList(item.data)
          break
      }
    }

    function addMarkerPointList (device) {
      store.dispatch('monitor/getMarkerPointList', device.deviceSerialNumber).then((res) => {
        store.commit('monitor/setMarkerPointList', res.data)
      }).catch((err) => {
        console.error(err)
      })
    }

    const ModalErrorDialg = ref()

    function showErrModal (err) {
      ModalErrorDialg.value.showModalWithData(false, '', err)
    }

    function onModalDeviceCallback (deviceData) {
      const originData = store.getters['clientAccount/accountDeviceDataByDeviceId'](deviceData.deviceId)
      const covertData = tools.objectDiff(originData, deviceData)
      const updateData = tools.CovertUpdateDeviceData(covertData)
      if (!tools.isEmpty(updateData)) {
        updateData.id = deviceData.deviceId
        store.dispatch('clientAccount/updateDeviceData', updateData).then((res) => {
          if (updateData.password) {
            delete updateData.password
          }
          store.commit('clientAccount/updateDeviceData', [updateData])
        }).catch((err) => {
          showErrModal(err)
        })
      }
    }

    function onRightClickCallback (event, itemData) {
      menuTop.value = event.pageY - 10
      menuLeft.value = event.pageX + 40
      switch (itemData.value.type) {
        case 'Fleet':
          RightClickFleetMenuList.value.showRightClickMenu(itemData.value)
          break
        case 'Device':
          RightClickDeviceMenuList.value.showRightClickMenu(itemData.value)
          break
        default:
          RightClickMainMenuList.value.showRightClickMenu(itemData.value)
          break
      }
    }

    function onModalSubFleetCallback (isUpdate, data = null) {
      if (isUpdate) {
        updateSubFleetAccount(data)
      } else {
        createSubFleetAccount(data)
      }
    }

    function createSubFleetAccount (data) {
      const createData = Object.assign({}, data)
      createData.parentFleetId = fleetData.value.id
      store.dispatch('adminFleet/createSubFleetAccount', createData).then((res) => {
        getListData()
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function updateSubFleetAccount (data) {
      const originData = store.getters['clientAccount/accountFleetDataById'](data.id)
      if (originData) {
        const updateData = tools.objectDiff(originData, data)
        updateData.id = data.id
        store.dispatch('adminFleet/updateSubFleetAccount', updateData).then((res) => {
          getListData()
        }).catch((err) => {
          showErrModal(err)
        })
      }
    }

    function onModalFenceCallback (editor, id, fenceData) {
      if (id === 0) {
        accountDeviceList.value.forEach(device => {
          fenceData.deviceSerialNumber = device.deviceSerialNumber
          store.dispatch('monitor/addFence', fenceData).then((res) => {
          }).catch((err) => {
            showErrModal(err)
          })
        })
      } else {
        const device = accountDeviceList.value.find(item => item.deviceId === id)
        if (device) {
          fenceData.deviceSerialNumber = device.deviceSerialNumber
          store.dispatch('monitor/addFence', fenceData).then((res) => {
          }).catch((err) => {
            showErrModal(err)
          })
        }
      }
    }

    function onMarkerPointCallback (editor, id, markerData) {
      if (id === 0) {
        accountDeviceList.value.forEach(device => {
          let data = {
            deviceSerialNumber: device.deviceSerialNumber
          }
          data = { ...data, ...markerData }
          store.dispatch('monitor/addMarkerPoint', data).then((res) => {
          }).catch((err) => {
            showErrModal(err)
          })
        })
      } else {
        const deviceData = accountDeviceList.value.find(device => device.deviceId === id)
        if (deviceData) {
          let data = {
            deviceSerialNumber: deviceData.deviceSerialNumber
          }
          data = { ...data, ...markerData }
          store.dispatch('monitor/addMarkerPoint', data).then((res) => {
          }).catch((err) => {
            showErrModal(err)
          })
        }
      }
    }

    function addRemoteSelectDevice (fleetData, isAllFleet) {
      fleetData.children.forEach(child => {
        if (child.type === 'Device') {
          store.commit('clientAccount/addSelectData', child)
        } else if (isAllFleet && child.type === 'Fleet') {
          addRemoteSelectDevice(child, isAllFleet)
        }
      })
    }

    function onMainMenuCallback (mainIndex, subData, data) {
      switch (mainIndex) {
        case 0:
          ModalSubFleetDialog.value.showModalDialog(false, Object.assign({}, defaultData.fleetSubData))
          break
        case 1:
          addRemoteSelectDevice(data, subData)
          router.push({ name: 'remote' })
          break
        case 2:
          router.push({ name: subData, params: { fleetId: data.id } })
          break
        case 3:
          if (subData === 0) {
            ModalClientElectoricFenceDialog.value.showModalWithDeviceId(0)
          } else {
            ModalClientMarkerPointDialog.value.showModalWithDeviceId(0)
          }
          break
      }
    }

    function onFleetMenuCallback (mainIndex, subIndex, data) {
      switch (mainIndex) {
        case 0:
          ModalSubFleetDialog.value.showModalDialog(true, data.data)
          break
        case 1:
          addRemoteSelectDevice(data, subIndex)
          router.push({ name: 'remote' })
          break
        case 2:
          router.push({ name: subIndex, params: { fleetId: data.id } })
          break
        case 3:
          if (subIndex === 0) {
            ModalClientElectoricFenceDialog.value.showModalWithDeviceId(0)
          } else {
            ModalClientMarkerPointDialog.value.showModalWithDeviceId(0)
          }
          break
      }
    }

    function onDeviceMenuCallback (mainIndex, subData, data) {
      switch (mainIndex) {
        case 0:
          store.commit('clientAccount/setShowTracker', true)
          router.push({ name: 'monitor' })
          break
        case 1:
          data.isChecked = true
          onSelectItemCallback(1, data)
          store.commit('clientAccount/setSelectDeviceId', data.id)
          router.push({ name: 'monitor' })
          break
        case 2:
          ModalClientDeviceViewDialog.value.showModalWithData(data)
          break
        case 3:
          router.push({ name: 'history', params: { deviceSerialNumber: data.deviceSerialNumber } })
          break
        case 4:
          setRemoteControl(subData, data)
          break
        case 5:
          router.push({ name: subData, params: { id: data.deviceId } })
          break
        case 6:
          if (subData === 0) {
            ModalClientElectoricFenceDialog.value.showModalWithDeviceId(data.deviceId)
          } else {
            ModalClientMarkerPointDialog.value.showModalWithDeviceId(data.deviceId)
          }
          break
        case 7:
          if (data.fleetId !== subData) {
            const params = {
              id: data.deviceId,
              fleetId: subData
            }
            console.log(JSON.stringify(params))
            store.dispatch('clientAccount/updateDeviceData', params).then((res) => {
              // getListData()
            }).catch((err) => {
              console.error(err)
              showErrModal(err)
            })
          }
          break
      }
    }

    function setRemoteControl (subData, data) {
      switch (subData) {
        case 'refreshDevice':
          store.dispatch('clientAccount/reflashDeviceData')
          break
        case 'remote':
          store.commit('clientAccount/addSelectData', data)
          router.push({ name: subData })
          break
        case 12:
        case 13:
          sendRemoteCommand(data.data.deviceSerialNumber, subData)
          break
      }
    }

    function sendRemoteCommand (serialNumber, cmdData) {
      let params = {
        requestDataCode: cmdData,
        deviceSerialNumber: serialNumber
      }
      params = { ...params, ...cmdData }
      store.dispatch('remote/sendRemoteStatus', params).then((res) => {
        store.commit('remote/addHistoryCommand', res.data)
      }).catch((err) => {
        showErrModal(err)
      })
    }

    function onSortTypeChanged () {
      store.commit('clientAccount/setSortType', sortType.value === 'serialNumber' ? 0 : 1)
      // store.commit('clientAccount/clearAllSelectData')
      // store.commit('monitor/clearDeviceMarkerPointList')
    }

    onMounted(() => {
      getSettingData()
      getListData(true)
      isShowMessage.value = false
      showIndex.value = 0
      showReciverMessage()
    })

    onUnmounted(() => {
      startReflashData.value = false
    })

    return {
      t,
      showLeftSide,
      accountDeviceList,
      messageList,
      announcementList,
      fleetData,
      selectData,
      disableSort,
      menuTop,
      menuLeft,
      sortType,
      showRightClickMenu,
      ModalSubFleetDialog,
      ModalErrorDialg,
      ModalClientDeviceViewDialog,
      ModalClientElectoricFenceDialog,
      ModalClientMarkerPointDialog,
      ModalShowMessageDialog,
      RightClickFleetMenuList,
      RightClickDeviceMenuList,
      RightClickMainMenuList,
      onSelectItemCallback,
      onRightClickCallback,
      onMainMenuCallback,
      onFleetMenuCallback,
      onDeviceMenuCallback,
      onModalSubFleetCallback,
      onModalFenceCallback,
      onMarkerPointCallback,
      onModalDeviceCallback,
      onShowReciverCallback,
      onSortTypeChanged
    }
  },
  components: {
    TreeView,
    ModalErrorMsg,
    RightClickFleetMenu,
    RightClickDeviceMenu,
    RightClickMainMenu,
    ModalSubFleet,
    ModalClientDeviceView,
    ModalClientElectoricFence,
    ModalClientMarkerPoint,
    ModalShowMessage
  }
}
</script>

<style lang="stylus" scoped>
.leftMenu {
  -webkit-transition: all 0s
  -moz-transition: all 0s
  -ms-transition: all 0s
  -o-transition: all 0s
  transition: all 0s
  &.slide-enter-active {
    transform: translate3d(-100%, 0, 0)
  }
  &.slide-leave-active {
    transform: translate3d(-100%, 0, 0)
  }
}
.searchBar {
  min-height: 2rem
}
.carList {
  overflow: auto
  height: calc(100vh - 10rem)
}
.topTitle {
  --bs-bg-opacity: 0.8;
  background-image: var(--bs-gradient)
}
// & input {
//   width: 10rem
// }
</style>
