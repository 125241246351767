import { request } from '../../../api/axios'
const state = {
  accountFleetList: [],
  accountDeviceList: [],
  selectDeviceList: [],
  selectFleetId: 0,
  selectDeviceIndex: -1,
  selectDeviceLogs: {},
  selectFilterBS: false,
  reflashDeviceFinish: false,
  selectShowTracker: false,
  sortType: 0
}

const getters = {
  selectDeviceTrackerLogs (state) {
    return state.selectDeviceLogs
  },
  selectDeviceIndex (state) {
    return state.selectDeviceIndex
  },
  selectDeviceList (state) {
    return state.selectDeviceList
  },
  selectShowMonitorDeviceList (state) {
    const monitorDevice = []
    let count = 9
    state.selectDeviceList.forEach(device => {
      if (count > 0) {
        count -= 1
        monitorDevice.push(device)
      }
    })
    return monitorDevice
  },
  selectFilterBaseStation (state) {
    return state.selectFilterBS
  },
  selectShowTracker (state) {
    return state.selectShowTracker
  },
  accountFleetList (state) {
    return state.accountFleetList
  },
  accountDeviceList (state) {
    return state.accountDeviceList
  },
  warningDeviceCount (state) {
    return state.accountDeviceList?.filter(device => (device.warnings && device.warnings.length > 0))?.length
  },
  vipDeviceList (state) {
    return state.accountDeviceList?.filter(device => device.isValueAddedVisible === 1)
  },
  accountFleetListData (state) {
    return state.accountFleetList
  },
  accountDeviceListByFleetId (state) {
    if (state.selectFleetId === 0) {
      return state.accountDeviceList
    } else {
      return state.accountDeviceList.filter(device => device.fleetId === state.selectFleetId)
    }
  },
  accountDeviceDataByDeviceSerialNumber: (state) => (deviceSerialNumber) => {
    return state.accountDeviceList.find(item => item.deviceSerialNumber.toString() === deviceSerialNumber)
  },
  accountDeviceDataByDeviceId: (state) => (deviceId) => {
    return state.accountDeviceList.find(item => item.deviceId === deviceId)
  },
  accountFleetDataById: (state) => (id) => {
    return state.accountFleetList.find(element => element.id === id)
  },
  accountMenuFleetList: (state, getters, rootState) => {
    const fleetList = []
    const mainFleet = Object.assign({}, rootState.userData)
    mainFleet.id = rootState.userData.accountId
    fleetList.push(mainFleet)
    state.accountFleetList.forEach(fleet => {
      fleetList.push(fleet)
    })
    return fleetList
  },
  accountDataList: (state, getters, rootState) => {
    if (!rootState.userData || !rootState.userData.accountType) {
      return []
    }
    if (rootState.userData.accountType === 3) {
      return {
        id: rootState.userData.accountId,
        name: (state.accountDeviceList[0]?.carName ?? '') + '(' + state.accountDeviceList[0]?.deviceSerialNumber + ')',
        type: 'Device',
        data: state.accountDeviceList[0]
      }
    }

    const data = {
      id: rootState.userData.accountId,
      name: rootState.userData.alias,
      children: []
    }
    const fleetList = []
    if (state.accountFleetList.length > 0) {
      state.accountFleetList.forEach(fleet => {
        const fleetData = {
          id: fleet.id,
          name: fleet.alias,
          type: 'Fleet',
          data: {
            id: fleet.id,
            parentFleetId: fleet.parentFleetId,
            account: fleet.account,
            alias: fleet.alias,
            permissions: fleet.permissions,
            loginTimestamp: fleet.loginTimestamp,
            numberOfDevices: fleet.numberOfDevices
          },
          children: []
        }
        fleetList.push(fleetData)
      })
    }
    state.accountDeviceList.forEach(device => {
      const fleet = fleetList.find(fleet => fleet.id === device.fleetId)
      const deviceData = {
        id: device.deviceId,
        name: (device.carName ?? (device.alias ?? '')) + '(' + device.deviceSerialNumber + ')',
        type: 'Device',
        data: device
      }
      const selectDevice = state.selectDeviceList.find(dev => dev.id === deviceData.id)
      if (selectDevice) {
        deviceData.isChecked = true
      }
      if (fleet) {
        fleet.children.push(deviceData)
      } else {
        data.children.push(deviceData)
      }
    })
    const subFleetList = getParentFleetList(data.id, fleetList)
    if (subFleetList.length > 0) {
      subFleetList.forEach(fleet => {
        data.children.unshift(fleet)
      })
    }
    if (state.sortType === 1) {
      sortByCarName(data.children)
    }
    return data
  },
  reflashDeviceFinish (state) {
    return state.reflashDeviceFinish
  }
}

const mutations = {
  setSortType (state, sortType) {
    state.sortType = sortType
  },
  setSelectFilterBS (state, isSelect) {
    state.selectFilterBS = isSelect
  },
  setShowTracker (state, isShow) {
    state.selectShowTracker = isShow
  },
  setSelectDeviceIndex (state, index) {
    if (state.selectDeviceIndex !== -1 && state.selectDeviceList[state.selectDeviceIndex]) {
      delete state.selectDeviceList[state.selectDeviceIndex].data.address
    }
    if (state.selectDeviceIndex !== -1 && state.selectDeviceList[state.selectDeviceIndex]) {
      delete state.selectDeviceList[state.selectDeviceIndex].data.addressGpsOnly
    }
    state.selectDeviceIndex = index
  },
  setSelectDeviceId (state, id) {
    const index = state.selectDeviceList.findIndex(device => device.id === id)
    if (index > -1) {
      state.selectDeviceIndex = index
    }
  },
  setSelectFleetId (state, fleetId) {
    state.selectFleetId = fleetId
  },
  addSelectData (state, data) {
    const index = state.selectDeviceList.findIndex(item => item.name === data.name)
    if (index === -1) {
      data.selectTimestamp = new Date().getTime() * 1000
      data.isRemoteSelected = false
      state.selectDeviceList.push(data)
    }
  },
  clearAllSelectData (state) {
    state.selectDeviceList.forEach(device => {
      delete state.selectDeviceLogs[device.name]
    })
    state.selectDeviceIndex = -1
    state.selectDeviceList.length = 0
  },
  updateSelectDeviceTrackerLogs (state, { deviceName: name, trackerLogs: logs }) {
    const index = state.selectDeviceList.findIndex(device => device.name === name)
    if (index !== -1) {
      if (state.selectDeviceLogs[name]) {
        if (logs.length === 0) {
          // TODO
        } else {
          logs.forEach(log => {
            state.selectDeviceLogs[name].trackerLogs.push(log)
            state.selectDeviceList[index].selectTimestamp = log.timestamp
          })
        }
      } else {
        state.selectDeviceLogs[name] = { deviceIndex: index, trackerLogs: logs }
      }
    }
  },
  removeSelectData (state, data) {
    const index = state.selectDeviceList.findIndex(item => item.name === data.name)
    if (index > -1) {
      state.selectDeviceList.splice(index, 1)
      if (state.selectDeviceIndex === index) {
        state.selectDeviceIndex = -1
      } else if (state.selectDeviceIndex > index) {
        state.selectDeviceIndex -= 1
      }
      delete state.selectDeviceLogs[data.name]
    }
  },
  updateDeviceData (state, data) {
    data.forEach(device => {
      if (!device.deviceId) {
        device.deviceId = device.id
      }
      const index = state.accountDeviceList.findIndex(item => item.deviceId === device.deviceId)
      if (index > -1) {
        Object.assign(state.accountDeviceList[index], device)
      }
    })
  },
  updateDeviceDataAddress (state, { deviceId, addressValue }) {
    state.selectDeviceList.forEach(device => {
      if (device.id === deviceId) {
        device.data.address = addressValue
      }
    })
  },
  updateDeviceDataAddressGpsOnly (state, { deviceId, addressValue }) {
    state.selectDeviceList.forEach(device => {
      if (device.id === deviceId) {
        device.data.addressGpsOnly = addressValue
      }
    })
  },
  addFleetDataList (state, dataList) {
    dataList.rows.forEach(fleetData => {
      setFleetChildrenData(state, fleetData)
    })
  },
  addFleetsList (state, list) {
    list.forEach(fleetData => {
      const fleet = {
        id: fleetData.id,
        parentFleetId: fleetData.parentFleetId,
        account: fleetData.account,
        alias: fleetData.alias,
        permissions: fleetData.permissions,
        loginTimestamp: fleetData.loginTimestamp,
        numberOfDevices: fleetData.numberOfDevices
      }
      if (fleetData.devices.length > 0) {
        fleetData.devices.forEach(device => {
          state.accountDeviceList.push(device)
        })
      }
      state.accountFleetList.push(fleet)
    })
  },
  addSubFleetList (state, data) {
    data.rows.forEach(element => {
      state.accountFleetList.push(element)
    })
  },
  addDeviceList (state, data) {
    data.rows.forEach(element => {
      state.accountDeviceList.push(element)
    })
  },
  addDeviceData (state, data) {
    state.accountDeviceList.push(data)
  },
  clearSubFleetDataList (state) {
    state.accountFleetList.length = 0
  },
  clearDeviceDataList (state) {
    state.selectDeviceIndex = -1
    state.accountDeviceList.length = 0
  },
  clearAllDataList (state) {
    state.selectDeviceIndex = -1
    state.selectDeviceList.length = 0
    state.accountFleetList.length = 0
    state.accountDeviceList.length = 0
  },
  reflashSelectTimestamp (state, timestamp) {
    state.selectDeviceList.forEach(device => {
      device.selectTimestamp = timestamp
    })
  },
  setFlashDeviceFinish (state, isFinish) {
    state.reflashDeviceFinish = isFinish
  }
}

const actions = {
  async getSubFleetData ({ rootState }, { token, fleetId }) {
    const params = {
      userToken: token,
      id: fleetId,
      containSubFleets: true,
      containFleetDevices: false
    }
    return request('/api/getFleets', JSON.stringify(params))
  },
  async getDeviceByFleetId ({ rootState }, parentFleetIdId) {
    const params = {
      userToken: rootState.userToken,
      fleetId: parentFleetIdId,
      isContainSubFleet: false,
      pageIndex: 0,
      pageRows: 1000
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async getMainFleetDevice ({ rootState }) {
    const params = {
      userToken: rootState.userToken,
      fleetId: rootState.userData.accountId,
      isContainSubFleet: false,
      pageIndex: 0,
      pageRows: 1000
    }
    return request('/api/getDevices', JSON.stringify(params))
  },
  async reflashDeviceData ({ rootState, state, commit }) {
    let params = {
      userToken: rootState.userToken,
      pageIndex: 0,
      pageRows: 1000
    }
    if (rootState.loginTimestamp > 0) {
      params = { ...params, ...{ lastLoadingTimestamp: new Date(new Date().setHours(0, 0, 0, 0)).getTime() * 1000 } }
    }
    commit('setFlashDeviceFinish', false)
    if (rootState.userData.accountType === 2) {
      params = { ...params, ...{ fleetId: rootState.userData.accountId, isContainSubFleet: false } }
      request('api/getDevices', JSON.stringify(params)).then((res) => {
        commit('updateDeviceData', res.data.rows)
        commit('clientWarning/updateWarningList', res.data, { root: true })
      })
      let totalFleetCount = state.accountFleetList?.length ?? 0
      state.accountFleetList.forEach(fleetData => {
        params.fleetId = fleetData.id
        request('api/getDevices', JSON.stringify(params)).then((res) => {
          commit('updateDeviceData', res.data.rows)
          commit('clientWarning/updateWarningList', res.data, { root: true })
          totalFleetCount--
          if (totalFleetCount === 0) {
            commit('setFlashDeviceFinish', true)
          }
        })
      })
    } else {
      params = { ...params, ...{ deviceSerialNumber: rootState.userData.account } }
      request('/api/getDevices', JSON.stringify(params)).then((res) => {
        commit('updateDeviceData', res.data.rows)
        commit('clientWarning/updateWarningList', res.data, { root: true })
        commit('setFlashDeviceFinish', true)
      })
    }
  },
  async updateDeviceData ({ rootState }, updateData) {
    let params = {
      userToken: rootState.userToken
    }
    params = { ...params, ...updateData }
    return request('/api/editDevice', JSON.stringify(params))
  },
  async forgetPassword ({ rootState }, data) {
    rootState.nowTime = 0
    return request('/api/requestForgetPassword', JSON.stringify(data))
  }
}

function setFleetChildrenData (state, fleetData) {
  if (fleetData.subFleets.length > 0) {
    fleetData.subFleets.forEach(subFleet => {
      const data = {
        id: subFleet.id,
        parentFleetId: subFleet.parentFleetId,
        account: subFleet.account,
        alias: subFleet.alias,
        permissions: subFleet.permissions,
        loginTimestamp: subFleet.loginTimestamp,
        numberOfDevices: subFleet.numberOfDevices
      }
      state.accountFleetList.push(data)
    })
  }
  if (fleetData.subFleets.length > 0) {
    fleetData.subFleets.forEach(fleet => {
      setFleetChildrenData(state, fleet)
    })
  }
}

function getParentFleetList (parentFleetId, fleetList) {
  const childFleetList = []
  const unUseFleetList = []
  fleetList.forEach(fleet => {
    if (fleet.data.parentFleetId === parentFleetId) {
      childFleetList.push(fleet)
    } else {
      unUseFleetList.push(fleet)
    }
  })
  if (unUseFleetList.length > 0) {
    childFleetList.forEach(fleetChild => {
      const childSubFleetList = getParentFleetList(fleetChild.id, unUseFleetList)
      if (childSubFleetList.length > 0) {
        childSubFleetList.forEach(fleet => {
          fleetChild.children.unshift(fleet)
        })
      }
    })
  }
  return childFleetList
}

function sortByCarName (childData) {
  childData.sort(function (a, b) {
    if (a.type === 'Fleet') {
      sortByCarName(a.children)
      return 0
    }
    if (a.data.carName < b.data.carName) { return -1 }
    if (a.data.carName > b.data.carName) { return 1 }
    return 0
  })
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
